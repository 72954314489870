import axios from 'axios';
import { toProperCase } from '../utils/stringUtils';

const API_URL = 'https://api4.thetvdb.com/v4';
const API_KEY = '7d627c00-f3dc-48fd-874b-4f50b0fa86f6';

let authToken = null;

const handleError = (error) => {
  console.error('TVDB API Error:', error);
  throw error;
};

const authenticate = async () => {
  try {
    const response = await axios.post(`${API_URL}/login`, { apikey: API_KEY });
    authToken = response.data.data.token;
    return authToken;
  } catch (error) {
    handleError(error);
  }
};

const getAuthHeaders = async () => {
  if (!authToken) {
    await authenticate();
  }
  return {
    'Authorization': `Bearer ${authToken}`,
    'Accept': 'application/json'
  };
};

export const search = async (query) => {
  try {
    const headers = await getAuthHeaders();
    const response = await axios.get(`${API_URL}/search`, {
      headers,
      params: { query }
    });

    return response.data.data.slice(0, 10).map(item => ({
      id: item.tvdb_id,
      name: item.name,
      type: toProperCase(item.type),
      network: item.network,
      year: item.year,
      overview: item.overview
    }));
  } catch (error) {
    // If authentication error, retry once with fresh token
    if (error.response?.status === 401) {
      authToken = null;
      const headers = await getAuthHeaders();
      const response = await axios.get(`${API_URL}/search`, {
        headers,
        params: { query }
      });
      return response.data.data.slice(0, 10);
    }
    handleError(error);
  }
};

export const getById = async (tvdbId) => {
  try {
    const headers = await getAuthHeaders();
    const response = await axios.get(`${API_URL}/series/${tvdbId}/extended`, { headers });

    const seriesData = response.data.data;
    const seasonData = seriesData.seasons || [];
    const seasons = {};

    seasonData
      .filter(x => x.number > 0 && x.type?.type?.toLowerCase() === 'official')
      .sort((a, b) => a - b)
      .forEach(x => { seasons[x.number] = "Unwatched" });

    return {
      id: seriesData.id.toString(),
      name: seriesData.name,
      type: "Series",
      year: seriesData.year,
      genres: seriesData.genres.map(x => x.name),
      status: seriesData.status.name,
      imageUrl: seriesData.image,
      overview: seriesData.overview,  
      seasons: seasons,
      originalNetwork: seriesData.originalNetwork.name,
      streamingOn: seriesData.originalNetwork.name
    };
  } catch (error) {
    handleError(error);
  }
};
import { 
    VisibilityOff, 
    Visibility, 
    PauseCircle, 
    CheckCircle 
  } from '@mui/icons-material';
  
  export const WATCH_STATUS = {
    NOT_STARTED: 'NOT_STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    PAUSED: 'PAUSED',
    COMPLETED: 'COMPLETED'
  };
  
  export const STATUS_ICONS = {
    [WATCH_STATUS.NOT_STARTED]: <VisibilityOff color="action" />,
    [WATCH_STATUS.IN_PROGRESS]: <Visibility color="primary" />,
    [WATCH_STATUS.PAUSED]: <PauseCircle color="warning" />,
    [WATCH_STATUS.COMPLETED]: <CheckCircle color="success" />
  };
  
  export const STATUS_LABELS = {
    [WATCH_STATUS.NOT_STARTED]: 'Not Started',
    [WATCH_STATUS.IN_PROGRESS]: 'In Progress',
    [WATCH_STATUS.PAUSED]: 'Paused',
    [WATCH_STATUS.COMPLETED]: 'Completed'
  };
  
  export function getWatchStatus(seasons) {
    const seasonStatuses = Object.values(seasons);
    const watchedCount = seasonStatuses.filter(status => status === 'Watched').length;
    const watchingCount = seasonStatuses.filter(status => status === 'Watching').length;
    
    if (seasonStatuses.length === 0) return WATCH_STATUS.NOT_STARTED;
    if (watchedCount === seasonStatuses.length) return WATCH_STATUS.COMPLETED;
    if (watchingCount > 0) return WATCH_STATUS.IN_PROGRESS;
    if (watchedCount > 0) return WATCH_STATUS.PAUSED;
    return WATCH_STATUS.NOT_STARTED;
  }
import React, { useEffect, useState, useMemo } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './components/HomePage';
import DetailsPage from './components/DetailsPage';
import SearchPage from './components/SearchPage';
import LoginPage from './components/LoginPage';
import { useAuth } from './hooks/useAuth';
import { getItems, addItem as dataApiAddItem, updateItem as dataApiUpdateItem, deleteItem as dataApiDeleteItem } from './api/data';
import { getById } from './api/tvdb';

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return children;
};

const App = () => {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        setLoading(true);
        setItems(await getItems());
      } catch (error) {
        console.error('Error fetching items:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [getItems]);

  const addItem = async (itemId) => {
    try {
      const item = await getById(itemId);
      await dataApiAddItem(item);
      setItems(current => [...current, item]);
      return item;
    } catch (err) {
      throw err;
    }
  };

  const handleItemRefresh = async (item) => {
    const seriesInfo = await getById(item.id);

    const updatedItem = {
      ...item,
      genres: seriesInfo.genres,
      status: seriesInfo.status,
      overview: seriesInfo.overview,
      imageUrl: seriesInfo.imageUrl,
      originalNetwork: seriesInfo.originalNetwork,
      seasons: { ...seriesInfo.seasons, ...item.seasons }
    };

    // Update the selected item
    setSelectedItem(updatedItem);

    // Also update the item in the main items array
    setItems(currentItems =>
      currentItems.map(item =>
        item.id === updatedItem.id ? updatedItem : item
      )
    );

    dataApiUpdateItem(updatedItem).catch(error => {

    });
  };

  const handleShowItemUpdate = async(item) => {
    const updatedItem = {
      ...item,
      seasons: item.seasons
    };

    // Update the selected item
    setSelectedItem(updatedItem);

    // Also update the item in the main items array
    setItems(currentItems =>
      currentItems.map(item =>
        item.id === updatedItem.id ? updatedItem : item
      )
    );

  }

  const handleSaveItemUpdate = async(item) => {
    dataApiUpdateItem(item).catch(error => {
    });
  }

  const handleItemDelete = async(item) => {
    setSelectedItem(undefined);
    setItems(items.filter(x => x.id !== item.id));

    await dataApiDeleteItem(item.id);
  }

  const networks = useMemo(() => {
    return [...new Set(items.map(item => item.streamingOn || item.originalNetwork).filter(Boolean))];
  }, [items]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<ProtectedRoute> <HomePage items={items} networks={networks} isLoaded={!loading} onItemSelected={(x) => setSelectedItem(x)} /> </ProtectedRoute>} />
        <Route path="/details" element={<ProtectedRoute><DetailsPage item={selectedItem} existingNetworks={networks} onItemRefresh={handleItemRefresh} onShowItemUpdate={handleShowItemUpdate} onSaveItemUpdate={handleSaveItemUpdate} onItemDelete={handleItemDelete}/></ProtectedRoute>} />
        <Route path="/search" element={<ProtectedRoute> <SearchPage onSelectedItem={addItem} /> </ProtectedRoute>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CircularProgress,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  TextField,
  Box,
  Chip,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import { ArrowBack, Refresh as RefreshIcon, Delete as DeleteIcon } from '@mui/icons-material';
import StatusButton, { WATCH_STATUS } from './StatusButton';
import _ from 'lodash';

const DetailsPage = ({ item, existingNetworks, onItemRefresh, onShowItemUpdate, onSaveItemUpdate, onItemDelete }) => {
  const navigate = useNavigate();
  const [newNetwork, setNewNetwork] = useState('');
  const [networkDialogOpen, setNetworkDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  const debouncedUpdateItem = useCallback(
    _.debounce((updatedItem) => {
      onSaveItemUpdate(updatedItem);
    }, 1000),
    []
  );

  if (!item) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const handleFieldChange = (field, value) => {
    const updatedItem = { ...item, [field]: value };

    onShowItemUpdate(updatedItem);
    debouncedUpdateItem(updatedItem);
  };

  const handleRefresh = async () => {
    try {
      setLoading(true);
      await onItemRefresh(item);
    } catch (error) {
      console.error('Error refreshing seasons:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddNetwork = () => {
    if (newNetwork.trim()) {
      handleFieldChange('network', newNetwork.trim());
      setNetworkDialogOpen(false);
      setNewNetwork('');
    }
  };

  const handleSeasonStatusChange = async (seasonNumber, newStatus) => {
    const updatedSeasons = {
      ...(item.seasons || {}),
      [seasonNumber]: newStatus
    };

    handleFieldChange('seasons', updatedSeasons);
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      await onItemDelete(item);
      setDeleteDialogOpen(false);
      navigate("/")
    } catch (error) {
      console.error('Error deleting TV show or movie:', error);
    }
  };

  // Convert seasons object to array for rendering
  const seasonNumbers = item.seasons
    ? Object.keys(item.seasons).map(Number).sort((a, b) => a - b)
    : [];

  if (!item) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', bgcolor: 'grey.50' }}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => navigate('/')} aria-label="back">
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" sx={{ ml: 2, flexGrow: 1 }}>
            Details
          </Typography>
          {item.type === 'Series' && (
            <IconButton
              color="inherit"
              onClick={handleRefresh}
              disabled={loading}
              aria-label="refresh seasons"
            >
              <RefreshIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      <Box sx={{ p: 2 }}>
        {/* Header Section with Image and Basic Info */}
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: 3,
          mb: 4
        }}>
          {/* Image Container */}
          <Box sx={{
            width: { xs: '100%', md: '33%' },
            flexShrink: 0
          }}>
            {item.imageUrl && (
              <Box
                component="img"
                src={item.imageUrl}
                alt={item.name}
                sx={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover',
                  borderRadius: 1,
                  boxShadow: 1
                }}
              />
            )}
          </Box>

          {/* Title and Overview Container */}
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
              {item.name}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              {item.type} • {item.originalNetwork} • {item.status}
            </Typography>
            {/* Add genres here */}
            {item.genres && item.genres.length > 0 && (
              <Box sx={{ display: 'flex', gap: 1, mb: 2, flexWrap: 'wrap' }}>
                {item.genres.map((genre) => (
                  <Chip
                    key={genre}
                    label={genre}
                    size="small"
                    variant="outlined"
                    sx={{
                      bgcolor: 'background.paper',
                      '&:hover': { bgcolor: 'background.paper' }
                    }}
                  />
                ))}
              </Box>
            )}
            <Typography variant="body1" color="text.secondary">
              {item.overview || 'No overview available'}
            </Typography>
          </Box>
        </Box>

        {/* Network Selection */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Streaming on</Typography>
          <Select
            fullWidth
            value={item.streamingOn || item.originalNetwork}
            onChange={(e) => handleFieldChange('streamingOn', e.target.value)}
            sx={{ bgcolor: 'background.paper' }}
          >
            {existingNetworks.map(network => (
              <MenuItem key={network} value={network}>{network}</MenuItem>
            ))}
            <MenuItem value="">
              <Button
                fullWidth
                onClick={(e) => {
                  e.stopPropagation();
                  setNetworkDialogOpen(true);
                }}
              >
                Add New Streaming Service
              </Button>
            </MenuItem>
          </Select>
        </Box>

        {/* Seasons Section */}
        {item.type === 'Series' && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Seasons</Typography>
            <Box sx={{ bgcolor: 'background.paper', borderRadius: 1, boxShadow: 1 }}>
              <List>
                {seasonNumbers.map((seasonNum) => (
                  <ListItem key={seasonNum} divider>
                    <ListItemText primary={`Season ${seasonNum}`} />
                    <ListItemSecondaryAction>
                      <StatusButton
                        status={item.seasons?.[seasonNum] || WATCH_STATUS.UNWATCHED}
                        onStatusChange={(newStatus) => handleSeasonStatusChange(seasonNum, newStatus)}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
                {seasonNumbers.length === 0 && (
                  <ListItem>
                    <ListItemText
                      secondary="No seasons information available. Click the refresh button to fetch seasons."
                    />
                  </ListItem>
                )}
              </List>
            </Box>
          </Box>
        )}

        <Button variant="contained" startIcon={<DeleteIcon />} disableElevation color="error" fullWidth onClick={() => handleDeleteClick(item)}>Delete</Button>
      </Box>

      {/* Network Dialog */}
      <Dialog open={networkDialogOpen} onClose={() => setNetworkDialogOpen(false)}>
        <DialogTitle>Add New Network</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            label="Network Name"
            value={newNetwork}
            onChange={(e) => setNewNetwork(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNetworkDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddNetwork} variant="contained">Add</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete "{item.name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DetailsPage;
import React, { useState } from 'react';
import { TextField, List, ListItem, ListItemText, CircularProgress, Button, IconButton, AppBar, Toolbar, Typography } from '@mui/material';
import { ArrowBack as ArrowBackIcon, Search as SearchIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { search, getById } from '../api/tvdb';
import { WATCH_STATUS } from './StatusButton';

const SearchPage = ({onSelectedItem}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleSearch = async () => {
        setIsLoading(true);

        try {
            const results = await search(searchQuery);
            setSearchResults(results);
        } catch (error) {
            console.error('Error searching TVDB:', error);
            setSearchResults([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault(); // Prevent form submission
        handleSearch();
    };

    const handleSelectItem = async (item) => {
        setIsLoading(true);
        await onSelectedItem(item.id);
        setIsLoading(false);
        navigate('/');
    };

    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => navigate('/')} aria-label="back">
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" style={{ marginLeft: '16px' }}>
                        Search
                    </Typography>
                </Toolbar>
            </AppBar>

            <div style={{ padding: '20px' }}>
                <form onSubmit={handleSearchSubmit} style={{ display: 'flex', marginBottom: '20px' }}>
                <TextField
                        autoFocus
                        fullWidth
                        placeholder="Search for TV Show or Movie"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <Button
                                    variant="contained"
                                    onClick={handleSearch}
                                    disabled={isLoading}
                                    aria-label="search"
                                >
                                    <SearchIcon />
                                </Button>
                            )
                        }}
                    />
                </form>
                {isLoading ? (
                    <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
                ) : (
                    <List>
                        {searchResults.map((item) => (
                            <ListItem key={item.id} onClick={() => handleSelectItem(item)}>
                                <ListItemText primary={item.name} 
                                secondary={
                                    <React.Fragment>
                                      <Typography component="span" variant="body2" color="textPrimary">
                                        {item.network || 'Unknown Network'}
                                      </Typography>
                                      {" — "}
                                      {item.year}
                                    </React.Fragment>
                                  } />
                            </ListItem>
                        ))}
                    </List>
                )}
            </div>
        </div>
    );
};

export default SearchPage;
import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { CheckCircle, VisibilityOff, PlayCircle } from '@mui/icons-material';

export const WATCH_STATUS = {
  UNWATCHED: 'Unwatched',
  WATCHING: 'Watching',
  WATCHED: 'Watched'
};

const StatusButton = ({ status, onStatusChange }) => {
  const getNextStatus = (currentStatus) => {
    switch (currentStatus) {
      case WATCH_STATUS.UNWATCHED:
        return WATCH_STATUS.WATCHING;
      case WATCH_STATUS.WATCHING:
        return WATCH_STATUS.WATCHED;
      case WATCH_STATUS.WATCHED:
        return WATCH_STATUS.UNWATCHED;
      default:
        return WATCH_STATUS.UNWATCHED;
    }
  };

  const getStatusIcon = () => {
    switch (status) {
      case WATCH_STATUS.WATCHING:
        return <PlayCircle sx={{ color: 'primary.main' }} />;
      case WATCH_STATUS.WATCHED:
        return <CheckCircle sx={{ color: 'success.main' }} />;
      default:
        return <VisibilityOff sx={{ color: 'action.disabled' }} />;
    }
  };

  return (
    <Tooltip title={status}>
      <IconButton 
        onClick={() => onStatusChange(getNextStatus(status))}
      >
        {getStatusIcon()}
      </IconButton>
    </Tooltip>
  );
};

export default StatusButton;
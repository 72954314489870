import React, { useState, useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  FormControlLabel,
  Checkbox,
  Divider,
  Typography,
  Button,
  Box
} from '@mui/material';
import { FilterList as FilterIcon, ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import _ from 'lodash';
import { WATCH_STATUS, STATUS_LABELS } from '../utils/watchStatus';

const FilterDrawer = ({ items, networks, onFiltersChange }) => {
  const [open, setOpen] = useState(false);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedNetworks, setSelectedNetworks] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const { logout } = useAuth();
  const navigate = useNavigate();

  // Get unique values for types
  const types = useMemo(() => _.uniq(items.map(item => item.type)).filter(Boolean), [items]);
  const genres = useMemo(() => _.uniq(items.flatMap(item => item.genres)).filter(Boolean), [items])

  const handleGenreChange = (genre) => {
    setSelectedGenres(prev => {
      const newGenres = prev.includes(genre)
        ? prev.filter(g => g !== genre)
        : [...prev, genre];

      onFiltersChange({ genres: newGenres, statuses: selectedStatuses, networks: selectedNetworks, types: selectedTypes })

      return newGenres;
    });
  }

  const handleStatusChange = (status) => {
    setSelectedStatuses(prev => {
      const newStatuses = prev.includes(status)
        ? prev.filter(s => s !== status)
        : [...prev, status];
      onFiltersChange({ statuses: newStatuses, genres: selectedGenres, networks: selectedNetworks, types: selectedTypes });
      return newStatuses;
    });
  };

  const handleNetworkChange = (network) => {
    setSelectedNetworks(prev => {
      const newNetworks = prev.includes(network)
        ? prev.filter(n => n !== network)
        : [...prev, network];
      onFiltersChange({ statuses: selectedStatuses, genres: selectedGenres, networks: newNetworks, types: selectedTypes });
      return newNetworks;
    });
  };

  const handleTypeChange = (type) => {
    setSelectedTypes(prev => {
      const newTypes = prev.includes(type)
        ? prev.filter(t => t !== type)
        : [...prev, type];
      onFiltersChange({ statuses: selectedStatuses, genres: selectedGenres, networks: selectedNetworks, types: newTypes });
      return newTypes;
    });
  };

  const logOut = () => {
    logout();
    navigate('/login');
  };

  const clearFilters = () => {
    setSelectedGenres([]);
    setSelectedStatuses([]);
    setSelectedNetworks([]);
    setSelectedTypes([]);
    onFiltersChange({ genres: [], statuses: [], networks: [], types: [] });
  };

  return (
    <>
      <IconButton
        color="inherit"
        onClick={() => setOpen(true)}
        sx={{
          position: 'relative',
          '& .filterDot': {
            position: 'absolute',
            top: 4,
            right: 4,
            width: 8,
            height: 8,
            bgcolor: 'error.main',
            borderRadius: '50%'
          }
        }}
      >
        <FilterIcon />
        {(selectedGenres.length > 0 || selectedStatuses.length > 0 || selectedNetworks.length > 0 || selectedTypes.length > 0) && (
          <div className="filterDot" />
        )}
      </IconButton>

      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '250px',
            boxSizing: 'border-box'
          }
        }}
      >
        <Box sx={{
          boxSizing: 'border-box',
          width: '100%',
          p: 3,
          maxWidth: '250px'
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3
          }}>
            <Typography variant="h6" sx={{ pl: 1 }}>Filters</Typography>
            <Button onClick={clearFilters} color="primary">
              Clear All
            </Button>
          </Box>

          <Divider sx={{ mb: 2 }} />

          <Accordion>
            <AccordionSummary aria-controls="panel1-content" id="panel1-header" expandIcon={<ArrowDropDownIcon />}>
              <Typography>Status</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <List sx={{ py: 0 }}>
                {Object.values(WATCH_STATUS).map((status) => (
                  <ListItem key={status} dense>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedStatuses.includes(status)}
                          onChange={() => handleStatusChange(status)}
                        />
                      }
                      label={STATUS_LABELS[status]}
                    />
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary aria-controls="panel2-content" id="panel2-header" expandIcon={<ArrowDropDownIcon />}>
              <Typography>Genre</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <List>
                {genres?.map((genre) => (
                  <ListItem key={genre} dense>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedGenres.includes(genre)}
                          onChange={() => handleGenreChange(genre)}
                        />
                      }
                      label={genre}
                    />
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary aria-controls="panel2-content" id="panel2-header" expandIcon={<ArrowDropDownIcon />}>
              <Typography>Network</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <List>
                {networks?.map((network) => (
                  <ListItem key={network} dense>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedNetworks.includes(network)}
                          onChange={() => handleNetworkChange(network)}
                        />
                      }
                      label={network}
                    />
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary aria-controls="panel2-content" id="panel2-header" expandIcon={<ArrowDropDownIcon />}>
              <Typography>Type</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <List>
                {types.map((type) => (
                  <ListItem key={type} dense>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedTypes.includes(type)}
                          onChange={() => handleTypeChange(type)}
                        />
                      }
                      label={type}
                    />
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Divider className="my-4" />

        <Button color="inherit" onClick={logOut} sx={{ ml: 2 }}>Log Out</Button>
      </Drawer>
    </>
  );
};

export default FilterDrawer;
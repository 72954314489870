import { useState, useCallback } from 'react';
import * as authService from '../services/auth';

export const useAuth = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(authService.isAuthenticated());
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const login = useCallback(async (username, password) => {
        setLoading(true);
        setError(null);
        
        try {
            await authService.login(username, password);
            setIsLoggedIn(true);
        } catch (err) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    }, []);

    const logout = useCallback(() => {
        authService.logout();
        setIsLoggedIn(false);
    }, []);

    return {
        isLoggedIn,
        loading,
        error,
        login,
        logout
    };
};
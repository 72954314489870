import React, { useState, useMemo } from 'react';
import {
  AppBar,
  Container,
  Fab,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Toolbar,
  Snackbar,
  Alert,
  CircularProgress,
  Box
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import FilterDrawer from './FilterDrawer';
import { STATUS_ICONS, getWatchStatus } from '../utils/watchStatus';

const HomePage = ({ items, networks, isLoaded, onItemSelected }) => {
  const [filters, setFilters] = useState({ genres: [], statuses: [], networks: [], types: [] });
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const navigate = useNavigate();

  const filteredItems = useMemo(() => {
    return items.filter(item => {
      const itemStatus = getWatchStatus(item.seasons);
      const genreMatch = filters.genres?.length === 0 || filters.genres?.some(genre => item.genres?.includes(genre));
      const statusMatch = filters.statuses.length === 0 || filters.statuses.includes(itemStatus);
      const networkMatch = filters.networks.length === 0 || filters.networks.includes(item.streamingOn);
      const typeMatch = filters.types.length === 0 || filters.types.includes(item.type);

      return statusMatch && networkMatch && typeMatch && genreMatch;
    }).sort((a, b) => a.name.localeCompare(b.name));;
  }, [items, filters]);

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
  };

  const handleItemSelected = (item) => {
    onItemSelected(item);
    navigate('/details');
  }

  if (!isLoaded) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          gap: 2
        }}
      >
        <CircularProgress size={48} />
        <Typography variant="body1" color="text.secondary">
          Loading your list...
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            LISTR
          </Typography>
          <FilterDrawer items={items} networks={networks} onFiltersChange={handleFiltersChange} />
        </Toolbar>
      </AppBar>

      <Container maxwidth="lg">
        {filteredItems.length === 0 && (
          <Typography variant="body1" className="text-center mt-8 text-gray-600">
            No items match your filters
          </Typography>
        )}
        <List sx={{mb:'76px'}}>
          {filteredItems.map((item) => (
            <ListItem key={item.id} onClick={() => handleItemSelected(item)}>
              <ListItemText
                primary={item.name}
                secondary={`${item.streamingOn || item.originalNetwork}`}
              />
              <ListItemSecondaryAction>
                {STATUS_ICONS[getWatchStatus(item.seasons)]}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Container>

      <Snackbar
        open={errorSnackbar}
        autoHideDuration={6000}
        onClose={() => setErrorSnackbar(false)}
      >
        <Alert
          onClose={() => setErrorSnackbar(false)}
          severity="error"
          className="w-full"
        >
          Failed to update status. Please try again.
        </Alert>
      </Snackbar>

      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: 'fixed',
          bottom: '20px',
          right: '20px'
        }}
        onClick={() => navigate('/search')}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

export default HomePage;
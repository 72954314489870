import axios from 'axios';

const createApiClient = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  if (!apiUrl) {
    throw new Error('API URL not configured. Make sure REACT_APP_API_URL is set in your .env file');
  }

  const client = axios.create({
    baseURL: apiUrl,
    timeout: 10000,
  });

  client.interceptors.request.use(
    (config) => {
      console.log('Making request to:', config.url);
      console.log('Request headers:', config.headers);
      return config;
    },
    (error) => {
      console.error('Request error:', error);
      return Promise.reject(error);
    }
  );

  client.interceptors.response.use(
    (response) => {
      console.log('Received response:', {
        status: response.status,
        headers: response.headers,
        data: response.data
      });
      return response;
    },
    (error) => {
      handleError('Request failed:', error);
      return Promise.reject(error);
    }
  );

  return client;
};

const apiClient = createApiClient();

const handleError = (message, error) => {
  console.error(message, {
    error: error,
    status: error.response?.status,
    statusText: error.response?.statusText,
    data: error.response?.data,
    config: {
      url: error.config?.url,
      method: error.config?.method,
      headers: error.config?.headers,
      data: error.config?.data
    }
  });
};

const getAuthHeaders = () => {
  const idToken = sessionStorage.getItem('idToken');
  if (!idToken) {
    throw new Error('User is not authenticated');
  }
  return {
    'Authorization': `Bearer ${idToken}`,
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  };
};

export const getItems = async () => {
  try {
    const headers = getAuthHeaders();
    console.log('Fetching items with headers:', headers);
    
    const response = await apiClient.get('/items', { 
      headers,
      validateStatus: status => status >= 200 && status < 300
    });
    
    return response.data;
  } catch (error) {
    handleError('Error fetching items:', error);
    throw error;
  }
};

export const addItem = async (item) => {
  try {
    const headers = getAuthHeaders();
    const response = await apiClient.post('/items', item, { 
      headers,
      validateStatus: status => status >= 200 && status < 300
    });
    return response.data;
  } catch (error) {
    handleError('Error adding item:', error);
    throw error;
  }
};

export const updateItem = async (item) => {
  try {
    const headers = getAuthHeaders();
    const response = await apiClient.put(`/items/${item.id}`, item, { 
      headers,
      validateStatus: status => status >= 200 && status < 300
    });
    return response.data;
  } catch (error) {
    handleError('Error updating item:', error);
    throw error;
  }
};

export const deleteItem = async (itemId) => {
  try {
    const headers = getAuthHeaders();
    const response = await apiClient.delete(`/items/${itemId}`, { 
      headers,
      validateStatus: status => status >= 200 && status < 300
    });
    return response.data;
  } catch (error) {
    handleError('Error deleting item:', error);
    throw error;
  }
};

export default {
  getItems,
  addItem,
  updateItem,
  deleteItem
};
// src/services/auth.js
import * as authApi from '../api/auth';

export const login = async (username, password) => {
    const authResult = await authApi.signIn(username, password);
    
    // Store tokens
    sessionStorage.setItem("idToken", authResult.IdToken || '');
    sessionStorage.setItem("accessToken", authResult.AccessToken || '');
    sessionStorage.setItem("refreshToken", authResult.RefreshToken || '');
};

export const logout = () => {
    sessionStorage.removeItem("idToken");
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
};

export const isAuthenticated = () => {
    const accessToken = sessionStorage.getItem('accessToken');
    const idToken = sessionStorage.getItem('idToken');
    return !!(accessToken && idToken);
};

export const register = async (email, password) => {
    await authApi.signUp(email, password);
    // You might want to automatically log in the user after registration
    // or handle other post-registration logic
};

export const confirmRegistration = async (username, code) => {
    await authApi.confirmSignUp(username, code);
};